/** @jsxImportSource react */
import clsx from 'clsx'
import { useKeenSlider } from 'keen-slider/react'
import { useState } from 'react'
import Control from './Control'
import ProductTeaser from './ProductTeaser'

type Product = {
  [key: string]: any
}

type Props = {
  products: Product[]
  controls?: boolean
  perView?: number | 'auto'
  initial?: number
}

export default function BestSellersSlider({ products, initial = 1, perView = 'auto', controls = true }: Props) {
  const [currentSlide, setCurrentSlide] = useState(initial)
  const [loaded, setLoaded] = useState(false)
  const [refCallback, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    renderMode: 'performance',
    slides: {
      origin: 'center',
      perView: 'auto',
      spacing: 16,
    },
    breakpoints: {
      '(min-width: 720px)': {
        initial,
        slides: {
          origin: 'center',
          perView,
          spacing: 40,
        },
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  })

  return (
    <div className={clsx('u-relative u-transition-opacity u-duration-1000', loaded ? 'u-opacity-100' : 'u-opacity-0')}>
      <div ref={refCallback} className="keen-slider">
        {products.map((product, idx) => (
          <ProductTeaser
            className="keen-slider__slide desktop:u-w-[320px]"
            key={product.id}
            product={product}
            size="lg"
            currentSlide={currentSlide === idx}
          />
        ))}
      </div>
      {loaded && slider.current && (
        <>
          <Control
            icon="arrow-left"
            className={clsx(
              'u-absolute u-left-[24px] u-top-1/2 u-translate-y-[-50%] lg:u-left-[50px]',
              !controls && 'desktop:u-hidden',
            )}
            onClick={() => slider?.current?.prev()}
          >
            <span className="u-sr-only">Previous Slide</span>
          </Control>
          <Control
            icon="arrow-right"
            className={clsx(
              'u-absolute u-right-[24px] u-top-1/2 u-translate-y-[-50%] lg:u-right-[50px]',
              !controls && 'desktop:u-hidden',
            )}
            onClick={() => slider?.current?.next()}
          >
            <span className="u-sr-only">Next Slide</span>
          </Control>
        </>
      )}
    </div>
  )
}
